export const environment = {
  production: false,
  apiUrl: 'https://oxzasoxhdap01.azurewebsites.net/v1',
  auth0: {
    domain: 'oxxohere-dev.us.auth0.com',
    clientId: 'KThG3UvKDbiMU7GwQyzyQX9dKzLpwXyv',
    authorizationParams: {
      audience: 'https://oxxohere-dev.us.externos.com/api/externos',
      redirect_uri: 'https://oxzasoxhdws02.azurewebsites.net/callback',
    },
    errorPath: '/callback',
  },
  imgStorage: 'https://oxzstaoxhdws01.blob.core.windows.net',
  mapsApiKey: 'AIzaSyA86xWnntLR07iaWDosllXhZPZPINzpKT0',
  logoExternos: 'https://oxzstaoxhdws01.blob.core.windows.net/oxzbloboxhd02/logo_1.png',
};
